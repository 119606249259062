import React, {useEffect, useState} from "react";
import styles from "./TeamProfile.module.scss";
import classNames from "classnames";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import * as FavouriteService from "../../services/FavouriteService";
import Footer from "../../components/Footer/Footer";
import * as DigitalAssetService from "../../services/DigitalAssetService";
import * as StatisticsService from "../../services/StatisticsService";
import {connect} from "react-redux";
import {ADVERTISEMENT_IMAGES, TEAM_KIT_SPONSORS} from "../../utilities/Constants";
import HeartActive from "../../assets/images/HeartActive.svg";
import HeartDefault from "../../assets/images/HeartDefault.svg";
import {formatDate, formatTime, isExistChecker} from "../../helpers/CommonFunctions";
import {toast} from "react-toastify";
import {Puff} from "react-loading-icons";
import * as actions from "../../store/actions";
import {useTranslation} from "react-i18next";
import Icon1 from "../../assets/images/points_icn_1.webp";
import Icon2 from "../../assets/images/points_icn_2.webp";
import shareIcon from "../../assets/images/icons/share_icon.svg";
import AdvertisementSpace from "../../components/AdvertisementSpace/AdvertisementSpace";

const TeamProfile = (props) => {
   const location = useLocation();
   const returnUrl = new URLSearchParams(location.search).get('returnUrl');
   const tournamentDetails = new URLSearchParams(location.search).get('tournamentDetails');
   const navigate = useNavigate();

   let {teamId} = useParams();
   const [teamData, setTeamData] = useState({});
   const [teamNews, setTeamNews] = useState([]);
   const [user, setUser] = useState({});
   let [loadingInProgress, setLoading] = useState(true);
   const [isGettingTeamData, setIsGettingTeamData] = useState(false);
   const [favouriteTeams, setFavouriteTeams] = useState([]);
   const [upcomingMatches, setUpcomingMatches] = useState([]);
   const [lastMatches, setLastMatches] = useState([]);
   const [teamKits, setTeamKits] = useState([]);
   const [teamSponsors, setTeamSponsors] = useState([]);
   const [favouriteLoading, setFavouriteLoading] = useState(false);
   const [activeLastMatchesViewAll, setActiveLastMatchesViewAll] = useState(false)
   const [totalUpcomingMatches, setTotalUpcomingMatches] = useState(0);
   const [totalLastMatches, setTotalLastMatches] = useState(0);
   const [totalTeamNews, setTotalTeamNews] = useState(0);
   const [lastOutcomeOfMatches, setLastOutcomeOfMatches] = useState([]);
   const [viewAllUpcoming, setViewAllUpcoming] = useState(true);
   const [viewAllLast, setViewAllLast] = useState(true);
   const [viewAllNews, setViewAllNews] = useState(true);
   const [loadingLastMatches, setLoadingLastMatches] = useState(true)
   const [loadingUpcomingMatches, setLoadingUpcomingMatches] = useState(true)
   const [loadingNews, setLoadingNews] = useState(true)
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [pageLang, setPageLang] = useState(localStorage.getItem('language'));

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);
   // Data Fetching functions
   const getTeamLatestNews = async (page, size) => {
      setLoadingNews(true);
      await DigitalAssetService.getTeamLatestNews(teamId, page, size, 'id,desc').then(
         (response) => {
            if (response.apiStatus) {
               setTeamNews(response.teamNews);
               setTotalTeamNews(response.totalElements)
            } else if (response.rejectMessages) {
               console.log(response.rejectMessages[0]);
            }
            setLoadingNews(false);
            setLoading(false);
         },
      );
   }

   const handleReturn = () => {
      console.log(returnUrl, tournamentDetails);
      if (tournamentDetails) {
         navigate(returnUrl, {state: {tournamentDetails: tournamentDetails}})
      } else if (returnUrl) {
         navigate(returnUrl);
      } else {
         navigate('/teams');
      }
   };

   const getCompletedMatchCardsByTeamId = async (page, size) => {
      setLoadingLastMatches(true)
      await StatisticsService.getAllMatchCardsByTeamId(page, size, 'isLive,isScheduled,matchId,desc', teamId, 'completed', pageLang, props.Token).then(
         (response) => {
            if (response.apiStatus) {
               setLastMatches(response.matches);
               setTotalLastMatches(response.totalElements)
            } else if (response.rejectMessages) {
               console.log(response.rejectMessages[0]);
            }
            setLoadingLastMatches(false)
            setLoading(false);
         },
      );
   }

   const getLastOutcomeOfMatchesByTeamId = async (noOfMatches, teamId) => {
      setLoadingLastMatches(true)
      await StatisticsService.getLastOutcomeOfMatchesByTeamId(noOfMatches, teamId).then(
         (response) => {
            if (response.apiStatus) {
               setLastOutcomeOfMatches(response.outcomes);
            } else if (response.rejectMessages) {
               console.log(response.rejectMessages[0]);
            }
            setLoadingLastMatches(false)
            setLoading(false);
         },
      );
   }

   const getUpcomingMatchCardsByTeamId = async (page, size) => {
      setLoadingUpcomingMatches(true)
      await StatisticsService.getAllMatchCardsByTeamId(page, size, 'isLive,isScheduled,matchId,asc', teamId, 'upcoming', pageLang, props.Token).then(
         (response) => {
            if (response.apiStatus) {
               setUpcomingMatches(response.matches);
               setTotalUpcomingMatches(response.totalElements)
            } else {
               setUpcomingMatches(null);
               setTotalUpcomingMatches(0)
            }
            setLoadingUpcomingMatches(false);
            setLoading(false);
         },
      );
   }
   const getTeamData = async () => {
      setIsGettingTeamData(true)
      await DigitalAssetService.getTeamProfileDetailsById(teamId, pageLang).then(
         (response) => {
            if (response.apiStatus) {
               setTeamData(response.teamProfileResponse);
            } else if (response.rejectMessages) {
               console.log(response.rejectMessages[0]);
            }
            setLoading(false);
         },
      );
      setIsGettingTeamData(false)
   }

   useEffect(() => {
      teamData?.teamId ? setLoading(false) : setLoading(true);
      getTeamData();
      getUpcomingMatchCardsByTeamId(0, 6);
      getCompletedMatchCardsByTeamId(0, 6);
      getTeamLatestNews(0, 6);
      getLastOutcomeOfMatchesByTeamId(10, teamId);

   }, [teamId]);

   useEffect(() => {
      setLoading(true);
      window.scrollTo({top: 0, behavior: "smooth"});
      const fetchDetails = async () => {
         if (props.isAuthenticated) {
            await FavouriteService.getFavourites(props.Token, "team", props.userName, 0, 100).then((response) => {
               if (response.data.apiStatus) {
                  setFavouriteTeams(response.data.userFavourites);
                  setFavouriteLoading(false);
               } else if (response.status == 401) {
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               } else if (response.data.rejectMessages) {
                  toast.error(response.data.rejectMessages[0]);
               }
            });
            setLoading(false);
            setFavouriteLoading(false);
         }
         favouriteTeamController();
      }
      fetchDetails();
   }, [teamId, user]);

   useEffect(() => {
      const fetchTeamKits = async () => {
         await DigitalAssetService.getTeamKitSponsors(TEAM_KIT_SPONSORS.KIT, teamId, 3, 0, 'id,desc')
            .then((response) => {
                  if (response.apiStatus) {
                     setTeamKits(response.teamKitSponsors?.filter((teamKit) => teamKit.kitSponsorType == TEAM_KIT_SPONSORS.KIT));

                  } else if (response.rejectMessages) {
                     console.log(response?.rejectMessages[0]);

                  }
               },
            );
         setLoading(false);
      }
      fetchTeamKits();
   }, [teamId]);

   useEffect(() => {
      const fetchTeamSponsors = async () => {
         await DigitalAssetService.getTeamKitSponsors(TEAM_KIT_SPONSORS.SPONSOR, teamId, 3, 0, 'id,desc').then(
            (response) => {
               if (response.apiStatus) {
                  setTeamSponsors(response.teamKitSponsors?.filter((teamKit) => teamKit.kitSponsorType == TEAM_KIT_SPONSORS.SPONSOR));
               } else if (response.rejectMessages) {
                  console.log(response.rejectMessages[0]);
               }
            },
         );
         setLoading(false);
      }
      fetchTeamSponsors();
   }, [teamId]);

   const updateFavouriteTeam = async (isFavourite, teamName, teamId) => {
      if (teamName != undefined || teamName != null) {

         if (isFavourite == true && favouriteTeams?.length > 0) {
            setFavouriteTeams(prevState => [...prevState, {id: teamId, name: teamName}]);
         } else if (isFavourite == false && favouriteTeams?.length > 0) {
            setFavouriteTeams((current) => current.filter((team) => team.id != teamId))
         } else if (isFavourite == true && favouriteTeams?.length == 0) {
            setFavouriteTeams([{id: teamId, name: teamName}]);
         } else if (isFavourite == false && favouriteTeams?.length == 0) {

         }
         const toastId = toast.loading(`${isFavourite ? t("TEAM_PROFILE.ADD") : t("TEAM_PROFILE.REMOVE")} ${t("TEAM_PROFILE.MATCHUP")} ${teamName} ${isFavourite ? t("TEAM_PROFILE.TO") : t("TEAM_PROFILE.FROM")} ${t("TEAM_PROFILE.FAVOURITES")}`)
         await FavouriteService.updateFavoriteTeam(props.Token, {
            id: parseInt(teamId),
            like: isFavourite,
            name: teamName,
            userName: props.userName
         }).then((response) => {
            if (response.data.apiStatus) {
               setUser(response.data.user);
               toast.update(toastId, {
                  render: `Successfully ${isFavourite ? t("TEAM_PROFILE.ADD") : t("TEAM_PROFILE.REMOVE")} ${t("TEAM_PROFILE.THE_MATCHUP")} ${teamName} ${isFavourite ? t("TEAM_PROFILE.TO") : t("TEAM_PROFILE.FROM")} ${t("TEAM_PROFILE.FAVOURITES")}`,
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            } else if (response.status == 401) {
               setFavouriteTeams((current) => current.filter((team) => team.id != teamId))
               toast.update(toastId, {
                  render: "Error Updating favourite Match-Up",
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
               //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
            } else {
               setFavouriteTeams((current) => current.filter((team) => team.id != teamId))

               toast.update(toastId, {
                  render: "Error Updating favourite Match-Up",
                  type: "error",
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
               // toast.error(response.data.rejectMessages[0]);
            }
         });
      } else {
         console.log("Error occurred in marking Favourite Match-Ups.");
      }
   };

   const favouriteTeamController = (teamName, teamId) => {
      if (isExistChecker(favouriteTeams, teamId)) {
         return (
            <button onClick={() => updateFavouriteTeam(false, teamName, teamId)} className="heartLikeFill">
               <img src={HeartActive} alt="HeartActive" className="heartActive"/>
            </button>
         )
      } else {
         return (
            <button onClick={() => updateFavouriteTeam(true, teamName, teamId)} className="heartLike">
               <img src={HeartDefault} alt="HeartDefault" className="heartDefault"/>
            </button>
         )
      }
   }

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   const ViewAll = (type) => {
      if (type == 'lastMatches') {
         setViewAllLast(false);
         getCompletedMatchCardsByTeamId(0, (totalLastMatches > 15 ? 15 : totalLastMatches))
      } else if (type == 'upcomingMatches') {
         setViewAllUpcoming(false)
         getUpcomingMatchCardsByTeamId(0, (totalUpcomingMatches > 15 ? 15 : totalUpcomingMatches))
      } else if (type == 'latestNews') {
         setViewAllNews(false)
         getTeamLatestNews(0, (totalTeamNews > 15 ? 15 : totalTeamNews))
      }
   }

   const ViewLess = (type) => {
      if (type == 'lastMatches') {
         setViewAllLast(true);
         getCompletedMatchCardsByTeamId(0, 6)
      } else if (type == 'upcomingMatches') {
         setViewAllUpcoming(true)
         getUpcomingMatchCardsByTeamId(0, 6)
      } else if (type == 'latestNews') {
         setViewAllNews(true)
         getTeamLatestNews(0, 6)
      }
   }
   return (
      <div className={classNames(styles.TeamProfilePage)}>
         <div className="pb-4">
            <div className={classNames(styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
               <div className="col-12  p-0">
                  <NavbarSecondary page="matches"/>
               </div>
               {!translationsLoaded ? <>
                     {loadingIndicator}</> :

                  <div className="col-12 p-0 pt-0 pt-md-5 row">
                     <div className="p-0 pt-0 row">
                        <div className="text-start col-12 p-0 pb-3">
                           <div onClick={() => handleReturn()} className={classNames(styles.flatBtn, "float-left", "p-2", "ps-3", "pe-3")} style={{width: "100px"}}>
                              <i class="fa-solid fa-angle-left"></i>&nbsp;&nbsp;{t("TEAM_PROFILE.BACK")}
                           </div>
                        </div>
                     </div>
                     <div className="p-0 pt-0 row">
                        {/* ---------------left  col ------------ */}
                        <div className="col-12 col-lg-9 p-0 pt-0 ">
                           <div className="p-0 pt-0 row">
                              <div className="col-12 p-0 pt-0">
                                 <div className={classNames(styles.HeaderWraper)}>
                                    <div className={classNames(styles.LogoContainer)}>
                                       <div className={classNames(styles.HeaderLogoWrapper)}>
                                          <img src={teamData?.pictureURL} onError={(e) => {
                                             e.target.onerror = null;
                                             e.target.src = "https://storage.googleapis.com/meta11/serviceplatform/gujrat-falcon-big.png"
                                          }}/>
                                       </div>
                                    </div>
                                    <div className={classNames(styles.HeaderContentContainer)}>
                                       <h2>{teamData?.teamName}</h2>
                                       <div className={classNames(styles.MatchOutcomes)}>
                                          <p>{t("TEAM_PROFILE.LAST")} {lastOutcomeOfMatches.length >= 10 ? 10 : lastOutcomeOfMatches.length} {t("TEAM_PROFILE.MATCH_OUTCOMES")}</p>
                                          <div className={classNames(styles.OutcomeIcons)}>
                                             {lastOutcomeOfMatches.slice(0, 10)?.map((match) => (
                                                <>
                                                   {match == "W" ? <img src={Icon2}/> : <img src={Icon1}/>}
                                                </>
                                             ))}
                                          </div>
                                       </div>
                                       {
                                          props.isAuthenticated ?
                                             <a>{favouriteTeamController(teamData?.teamName, teamData?.teamId)}</a>
                                             : <></>
                                       }
                                       <p>
                                          {teamData?.teamBio ? teamData?.teamBio : ''}
                                       </p>
                                       <div className="p-0 pt-0 row">
                                          <div className="col-12 p-0 row pt-4">

                                             <div className="col-3 p-0 pe-1 pe-md-2">
                                                <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop1, "p-1", "p-md-3", "text-center")}>
                                                   <div className={classNames(styles.SumText)}>{teamData?.totalMatchCount ? teamData?.totalMatchCount : '0'}</div>
                                                   {t("TEAM_PROFILE.MATCHES")}
                                                </div>
                                             </div>

                                             <div className="col-3 p-0 ps-0 pe-1 ps-md-2 pe-md-2">
                                                <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop2, "p-1", "p-md-3", "text-center")}>
                                                   <div className={classNames(styles.SumText)}>{teamData?.winCount ? teamData?.winCount : '0'}</div>
                                                   {t("TEAM_PROFILE.WINS")}
                                                </div>
                                             </div>


                                             <div className="col-3 p-0 ps-0 pe-1 ps-md-2 pe-md-2">
                                                <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop3, "p-1", "p-md-3", "text-center")}>
                                                   <div className={classNames(styles.SumText)}>{teamData?.drawCount ? teamData?.drawCount : '0'}</div>
                                                   {t("TEAM_PROFILE.DRAWS")}
                                                </div>
                                             </div>

                                             <div className="col-3 p-0  ps-0 pe-0 ps-md-2 pe-md-0">
                                                <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop1, "p-1", "p-md-3", "text-center")}>
                                                   <div className={classNames(styles.SumText)}>{teamData?.lossCount ? teamData?.lossCount : '0'}</div>
                                                   {t("TEAM_PROFILE.LOSSES")}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* -------------row brake------------ */}
                           <div className="p-0 pt-0 row">
                              <div className="col-12 p-0 pt-1">
                                 <div className={classNames(styles.TeamPlayers)}>
                                    <div className={classNames(styles.ButtonRow, "p-0 pt-0 row")}>
                                       <h2>{t("TEAM_PROFILE.TEAM_PLAYERS")}</h2>
                                    </div>
                                    <div className="p-0 pt-0 row">
                                       <div className={classNames(styles.TeamPlayersWrapper, "p-0")}>
                                          {teamData?.playerProfiles?.slice(0, 6).map((player, index) => (
                                             <Link className="" to={{pathname: `/player/${player?.playerId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                                <div className={classNames(styles.TeamPlayerCard)}>
                                                   <div className={classNames(styles.ProfilePane, styles.SumTextSm,
                                                      index % 3 === 1 ? styles.borderTop2 : index % 3 === 2 ? styles.borderTop3 : styles.borderTop1,
                                                      "p-1", "p-md-3", "text-center")}>
                                                      <section style={{backgroundImage: `url(${player.pictureURL})`}}></section>
                                                      <h3>{player.playerName}</h3>
                                                   </div>
                                                </div>
                                             </Link>
                                          ))}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* -------------row brake------------ */}

                           <div className="p-0 pt-0 row">
                              <div className="col-12 p-0 pt-1">
                                 <div className={classNames(styles.TeamPlayers)}>
                                    {/* <div className={classNames(styles.ButtonRow,"p-0 pt-0 row")}>
                                                        <h2>
                                                            Upcoming Matches header
                                                        </h2>
                                                        <button>
                                                            View all
                                                        </button>
                                                    </div> */}
                                    <div className="p-0 pt-0 row">

                                       {/* Upcoming matches*/}
                                       <div className="mt-2">
                                          <div className={classNames(styles.SubTopic, "mt-5", "mb-4", "float-start")}>{t("TEAM_PROFILE.UPCOMING_MATCHES")}</div>
                                          {totalUpcomingMatches > 6 && viewAllUpcoming ?
                                             <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewAll('upcomingMatches')}>{t("TEAM_PROFILE.VIEW_ALL")}</div>
                                             : <>
                                                {totalUpcomingMatches > 6 && (viewAllUpcoming == false) ?
                                                   <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewLess('upcomingMatches')}>{t("TEAM_PROFILE.VIEW_LESS")}</div>
                                                   : <></>
                                                }
                                             </>}
                                       </div>
                                       {upcomingMatches ?
                                          <>
                                             {loadingUpcomingMatches ? <Puff height={'2em'} strokeWidth={5} speed={2}/> :
                                                <div className="col-12 p-0 row">
                                                   {upcomingMatches?.map((match, i) => (
                                                      <div className="col-12 col-sm-6 col-md-4 p-0 pe-3 pb-3">
                                                         <Link to={`/match/${match?.matchId}`} style={{textDecoration: 'none'}}>
                                                            <div className={classNames(styles.OverviewBox, "p-0")}>
                                                               <div className={classNames(styles.text12OS, "p-2", "text-center float-left w-100")} style={{borderBottom: "1px solid #FFFFFF0F"}}>

                                                                  <div className="float-end pl-1"><Link to={'/matches'} onClick={e => e.preventDefault()}><img src={shareIcon} alt={""}/></Link></div>
                                                                  <div className="float-start text-start pt-1 col-10 p-0">{formatDate(match?.matchStartTime)} {t("TEAM_PROFILE.STARTS_AT")} {formatTime(match?.matchStartTime)}</div>
                                                               </div>
                                                               <div className="col-12 row p-3">
                                                                  <div className="col-2 p-0"><img src={match?.team1PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>
                                                                  <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team1Name}</div>
                                                                  <div className="col-4 text-end p-0">
                                                                     <font className={classNames(styles.text24SolinaItaBold)}>{match.team1Runs ? match?.team1Runs : '0'}/{match?.team1Wickets ? match?.team1Wickets : '0'}</font><br/>
                                                                     <font className={classNames(styles.text12OS)}>({match?.team1Overs ? match?.team1balls != 6 ? match?.team1Overs - 1
                                                                        : match?.team1Overs : '0'}.{match?.team1Balls ? match?.team1Balls != 6 ? match?.team1Balls : 0 : '0'})</font>
                                                                  </div>
                                                               </div>

                                                               <div className="col-12 row p-3">
                                                                  <div className="col-2 p-0"><img src={match?.team2PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>
                                                                  <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team2Name}</div>
                                                                  <div className="col-4 text-end p-0">
                                                                     <font className={classNames(styles.text24SolinaItaBold)}>{match.team2Runs ? match?.team2Runs : '0'}/{match?.team2Wickets ? match?.team2Wickets : '0'}</font><br/>
                                                                     <font className={classNames(styles.text12OS)}>({match?.team2Overs ? match?.team2balls != 6 ? match?.team2Overs - 1
                                                                        : match?.team2Overs : '0'}.{match?.team2Balls ? match?.team2Balls != 6 ? match?.team2Balls : 0 : '0'})</font>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </Link>
                                                      </div>
                                                   ))}
                                                </div>}
                                          </>
                                          : <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>
                                             {t("TEAM_PROFILE.NO_UPCOMING_MATCHES_AT_THE_MOMENT")}
                                          </div>
                                       }

                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* -------------row brake------------ */}

                           <div className="p-0 pt-0 row">
                              <div className="col-12 p-0 pt-1">
                                 <div className={classNames(styles.TeamPlayers)}>
                                    <div className="p-0 pt-0 row">

                                       {/* Last matches*/}
                                       <div className="mt-2">
                                          <div className={classNames(styles.SubTopic, "mt-5", "mb-4", "float-start")}>{t("TEAM_PROFILE.LAST_MATCHES")}</div>
                                          {totalLastMatches > 6 && viewAllLast ? <>
                                             <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewAll('lastMatches')}>{t("TEAM_PROFILE.VIEW_ALL")}</div>
                                          </> : <>
                                             {totalLastMatches > 6 && (viewAllLast == false) ?
                                                <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewLess('lastMatches')}>{t("TEAM_PROFILE.VIEW_LESS")}</div>
                                                : <></>
                                             }
                                          </>}
                                       </div>
                                       { /*<Puff height={'2em'} strokeWidth={5} speed={2}/>*/}
                                       {lastMatches ? <>
                                             {loadingLastMatches ? <Puff height={'2em'} strokeWidth={5} speed={2}/> :
                                                <div className={classNames(styles.LastMatchesCards, "col-12 p-0 row")}>
                                                   {lastMatches?.map((match, i) => (
                                                      <div className="col-12 col-sm-6 col-md-4 p-0 pe-0 pe-md-3 pb-3" key={i}>
                                                         {console.log(match)}
                                                         <Link to={`/match/${match?.matchId}`} style={{textDecoration: 'none'}}>
                                                            <div className={classNames(styles.OverviewBox, "p-0")}>
                                                               <div className={classNames(styles.text12OS, "p-2", "text-center float-left w-100")} style={{borderBottom: "1px solid #FFFFFF0F"}}>
                                                                  <div className="float-right pl-1"><Link to={'/matches'} onClick={e => e.preventDefault()}><img src={shareIcon} alt={""}/></Link></div>
                                                                  <div className="float-left col-10 text-start p-0 pl-1 pt-1">{match?.message}</div>
                                                               </div>
                                                               <div className="col-12 row p-3">
                                                                  <div className="col-2 p-0"><img src={match?.team1PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>
                                                                  <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team1Name}</div>
                                                                  <div className="col-4 text-end p-0">
                                                                     <font className={classNames(styles.text24SolinaItaBold)}
                                                                           style={{color: match?.winnerId == match?.team1Id ? "#17EA85" : "#95A6B7"}}>{match?.team1Runs ? match?.team1Runs : '0'}/{match?.team1Wickets ? match?.team1Wickets : '0'}</font><br/>
                                                                     <font className={classNames(styles.text12OS)}>({match?.team1Overs ? match?.team1balls != 6 ? match?.team1Overs - 1
                                                                        : match?.team1Overs : '0'}.{match?.team1Balls ? match?.team1Balls != 6 ? match?.team1Balls : 0 : '0'})
                                                                     </font>
                                                                  </div>
                                                               </div>

                                                               <div className="col-12 row p-3">
                                                                  <div className="col-2 p-0"><img src={match?.team2PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>
                                                                  <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team2Name}</div>
                                                                  <div className="col-4 text-end p-0">
                                                                     <font className={classNames(styles.text24SolinaItaBold)}
                                                                           style={{color: match?.winnerId == match?.team2Id ? "#17EA85" : "#95A6B7"}}>{match?.team2Runs ? match?.team2Runs : '0'}/{match?.team2Wickets ? match?.team2Wickets : '0'}</font><br/>
                                                                     <font className={classNames(styles.text12OS)}>({match?.team2Overs ? match?.team2balls != 6 ? match?.team2Overs - 1
                                                                        : match?.team2Overs : '0'}.{match?.team2Balls ? match?.team2Balls != 6 ? match?.team2Balls : 0 : '0'})</font>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </Link>
                                                      </div>
                                                   ))}
                                                </div>}
                                          </> :
                                          <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>
                                             {t("TEAM_PROFILE.NO_UPCOMING_MATCHES_AT_THE_MOMENT")}
                                          </div>
                                       }
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* -------------row brake------------ */}
                        </div>
                        {/* ---------------right  col ------------ */}
                        <AdvertisementSpace imageUrl1={ADVERTISEMENT_IMAGES.BANNER_4}/>
                     </div>

                     {/* ------------------ old ----------------- */}
                     {/*<>*/}
                     {/*        <div className="col-12 col-lg-9 p-0 pt-1 ">*/}
                     {/*            <div className="col-12 p-0 row">*/}
                     {/*                <div className="col-12 col-md-4 p-0 pe-0 pe-md-4 pb-4 pb-md-0">*/}
                     {/*                    <div className={classNames(styles.picSection)}>*/}
                     {/*                        /!*{props.isAuthenticated ? <>*/}

                     {/*                            {favouriteTeamController(teamData?.teamName, teamData?.teamId)}*/}

                     {/*                        </> : <></>}*!/*/}

                     {/*                        <div className="text-center">*/}
                     {/*                            <img src={teamData?.pictureURL} onError={(e) => {*/}
                     {/*                                e.target.onerror = null;*/}
                     {/*                                e.target.src = "https://storage.googleapis.com/meta11/serviceplatform/gujrat-falcon-big.png"*/}
                     {/*                            }} style={{maxHeight:'280px',marginRight:'auto',marginLeft:'auto'}}/>*/}
                     {/*                        </div>*/}
                     {/*                    </div>*/}
                     {/*                </div>*/}

                     {/*                <div className="col-12 col-md-8 p-0">*/}
                     {/*                    <div className={classNames(styles.ProfilePane, "p-3", "pt-2", "pb-3")}>*/}
                     {/*                       {props.isAuthenticated ? <>*/}

                     {/*                            {favouriteTeamController(teamData?.teamName, teamData?.teamId)}*/}

                     {/*                        </> : <></>}*/}
                     {/*                        <div className={classNames(styles.Text24Solina, "mt-1")}>{teamData?.teamName}</div>*/}
                     {/*                        {teamData?.playerProfiles?.[0]?.playerId ?<Link style={{textDecoration: 'none'}} className={classNames(styles.sublinkbtn)} to={{pathname: `/player/${teamData?.playerProfiles?.[0]?.playerId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>*/}
                     {/*                            {t("TEAM_PROFILE.TEAM_PROFILE")} <FaExternalLinkAlt style={{marginLeft: 5}}/>*/}
                     {/*                        </Link>:<></>}*/}
                     {/*                        <br/><br/>*/}
                     {/*                        <text className={classNames(styles.text16OS)}>*/}
                     {/*                          {teamData?.teamBio ? teamData?.teamBio:''}*/}
                     {/*                        </text>*/}
                     {/*                    </div>*/}
                     {/*                    <div className="col-12 p-0 row pt-4">*/}

                     {/*                        <div className="col-3 p-0 pe-1 pe-md-2">*/}
                     {/*                            <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop1, "p-1","p-md-3", "text-center")}>*/}
                     {/*                                <div className={classNames(styles.SumText)}>{teamData?.totalMatchCount ? teamData?.totalMatchCount : '0'}</div>*/}
                     {/*                                {t("TEAM_PROFILE.MATCHES")}*/}
                     {/*                            </div>*/}
                     {/*                        </div>*/}

                     {/*                        <div className="col-3 p-0 ps-0 pe-1 ps-md-2 pe-md-2">*/}
                     {/*                            <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop2, "p-1","p-md-3", "text-center")}>*/}
                     {/*                                <div className={classNames(styles.SumText)}>{teamData?.winCount ? teamData?.winCount : '0'}</div>*/}
                     {/*                                {t("TEAM_PROFILE.WINS")}*/}
                     {/*                            </div>*/}
                     {/*                        </div>*/}


                     {/*                        <div className="col-3 p-0 ps-0 pe-1 ps-md-2 pe-md-2">*/}
                     {/*                            <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop3, "p-1","p-md-3", "text-center")}>*/}
                     {/*                                <div className={classNames(styles.SumText)}>{teamData?.drawCount ? teamData?.drawCount : '0'}</div>*/}
                     {/*                                {t("TEAM_PROFILE.DRAWS")}*/}
                     {/*                            </div>*/}
                     {/*                        </div>*/}

                     {/*                        <div className="col-3 p-0  ps-0 pe-0 ps-md-2 pe-md-0">*/}
                     {/*                            <div className={classNames(styles.ProfilePane, styles.SumTextSm, styles.borderTop1, "p-1","p-md-3", "text-center")}>*/}
                     {/*                                <div className={classNames(styles.SumText)}>{teamData?.lossCount ? teamData?.lossCount : '0'}</div>*/}
                     {/*                                {t("TEAM_PROFILE.LOSSES")}*/}
                     {/*                            </div>*/}
                     {/*                        </div>*/}
                     {/*                    </div>*/}
                     {/*                </div>*/}

                     {/*                /!* Upcoming matches*!/*/}
                     {/*                <div className="mt-2">*/}
                     {/*                    <div className={classNames(styles.SubTopic, "mt-5", "mb-4", "float-start")}>{t("TEAM_PROFILE.UPCOMING_MATCHES")}</div>*/}
                     {/*                  {totalUpcomingMatches > 6 && viewAllUpcoming ? <>*/}
                     {/*                        <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewAll('upcomingMatches')}>{t("TEAM_PROFILE.VIEW_ALL")}</div>*/}
                     {/*                  </> : <>*/}
                     {/*                    {totalUpcomingMatches > 6 && (viewAllUpcoming == false) ?*/}
                     {/*                      <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewLess('upcomingMatches')}>{t("TEAM_PROFILE.VIEW_LESS")}</div>*/}
                     {/*                      : <></>*/}
                     {/*                    }*/}
                     {/*                  </>}*/}
                     {/*                </div>*/}
                     {/*                {upcomingMatches ?*/}
                     {/*                    <>*/}
                     {/*                      {loadingUpcomingMatches ? <Puff height={'2em'} strokeWidth={5} speed={2}/> :*/}
                     {/*                        <div className="col-12 p-0 row">*/}
                     {/*                            {upcomingMatches?.map((match, i) => (*/}
                     {/*                                <div className="col-12 col-sm-6 col-md-4 p-0 pe-3 pb-3">*/}
                     {/*                                    <Link to={`/match/${match?.matchId}`} style={{textDecoration: 'none'}}>*/}
                     {/*                                        <div className={classNames(styles.OverviewBox, "p-0")}>*/}
                     {/*                                            <div className={classNames(styles.text12OS, "p-3", "text-center")} style={{borderBottom: "1px solid #FFFFFF0F"}}>{formatDate(match?.matchStartTime)} {t("TEAM_PROFILE.STARTS_AT")} {formatTime(match?.matchStartTime)} GMT</div>*/}
                     {/*                                            <div className="col-12 row p-3">*/}
                     {/*                                                <div className="col-2 p-0"><img src={match?.team1PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>*/}
                     {/*                                                <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team1Name}</div>*/}
                     {/*                                                <div className="col-4 text-end p-0">*/}
                     {/*                                                    <font className={classNames(styles.text24SolinaItaBold)}>{match.team1Runs ? match?.team1Runs : '0'}/{match?.team1Wickets ? match?.team1Wickets : '0'}</font><br/>*/}
                     {/*                                                    <font className={classNames(styles.text12OS)}>({match?.team1Overs ? match?.team1balls != 6 ? match?.team1Overs - 1*/}
                     {/*                                                        : match?.team1Overs : '0'}.{match?.team1Balls ? match?.team1Balls != 6 ? match?.team1Balls : 0 : '0'})</font>*/}
                     {/*                                                </div>*/}
                     {/*                                            </div>*/}

                     {/*                                            <div className="col-12 row p-3">*/}
                     {/*                                                <div className="col-2 p-0"><img src={match?.team2PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>*/}
                     {/*                                                <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team2Name}</div>*/}
                     {/*                                                <div className="col-4 text-end p-0">*/}
                     {/*                                                    <font className={classNames(styles.text24SolinaItaBold)}>{match.team2Runs ? match?.team2Runs : '0'}/{match?.team2Wickets ? match?.team2Wickets : '0'}</font><br/>*/}
                     {/*                                                    <font className={classNames(styles.text12OS)}>({match?.team2Overs ? match?.team2balls != 6 ? match?.team2Overs - 1*/}
                     {/*                                                        : match?.team2Overs : '0'}.{match?.team2Balls ? match?.team2Balls != 6 ? match?.team2Balls : 0 : '0'})</font>*/}
                     {/*                                                </div>*/}
                     {/*                                            </div>*/}
                     {/*                                        </div>*/}
                     {/*                                    </Link>*/}
                     {/*                                </div>*/}
                     {/*                            ))}*/}
                     {/*                        </div>}*/}
                     {/*                    </>*/}
                     {/*                    : <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>*/}
                     {/*                        {t("TEAM_PROFILE.NO_UPCOMING_MATCHES_AT_THE_MOMENT")}*/}
                     {/*                    </div>*/}
                     {/*                }*/}

                     {/*                /!* Last matches*!/*/}
                     {/*                <div className="mt-2">*/}
                     {/*                    <div className={classNames(styles.SubTopic, "mt-5", "mb-4", "float-start")}>{t("TEAM_PROFILE.LAST_MATCHES")}</div>*/}
                     {/*                  {totalLastMatches > 6 && viewAllLast ? <>*/}
                     {/*                        <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewAll('lastMatches')}>{t("TEAM_PROFILE.VIEW_ALL")}</div>*/}
                     {/*                  </> : <>*/}
                     {/*                    {totalLastMatches > 6 && (viewAllLast == false) ?*/}
                     {/*                      <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewLess('lastMatches')}>{t("TEAM_PROFILE.VIEW_LESS")}</div>*/}
                     {/*                      : <></>*/}
                     {/*                    }*/}
                     {/*                  </>}*/}
                     {/*                </div>*/}
                     {/*              { /*<Puff height={'2em'} strokeWidth={5} speed={2}/>*!/*/}
                     {/*                {lastMatches ? <>*/}
                     {/*                    {loadingLastMatches ? <Puff height={'2em'} strokeWidth={5} speed={2}/> :*/}
                     {/*                        <div className="col-12 p-0 row">*/}
                     {/*                            {lastMatches?.map((match, i) => (*/}
                     {/*                                <div className="col-12 col-sm-6 col-md-4 p-0 pe-0 pe-md-3 pb-3" key={i}>*/}
                     {/*                                    <Link to={`/match/${match?.matchId}`} style={{textDecoration: 'none'}}>*/}
                     {/*                                        <div className={classNames(styles.OverviewBox, "p-0")}>*/}
                     {/*                                            <div className={classNames(styles.text12OS, "p-3", "text-center")} style={{borderBottom: "1px solid #FFFFFF0F"}}>{match?.message}</div>*/}
                     {/*                                            <div className="col-12 row p-3">*/}
                     {/*                                                <div className="col-2 p-0"><img src={match?.team1PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>*/}
                     {/*                                                <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team1Name}</div>*/}
                     {/*                                                <div className="col-4 text-end p-0">*/}
                     {/*                                                    <font className={classNames(styles.text24SolinaItaBold)}*/}
                     {/*                                                          style={{color: match?.winnerId == 1 ? "#17EA85" : "#95A6B7"}}>{match?.team1Runs ? match?.team1Runs : '0'}/{match?.team1Wickets ? match?.team1Wickets : '0'}</font><br/>*/}
                     {/*                                                    <font className={classNames(styles.text12OS)}>({match?.team1Overs ? match?.team1balls != 6 ? match?.team1Overs - 1*/}
                     {/*                                                        : match?.team1Overs : '0'}.{match?.team1Balls ? match?.team1Balls != 6 ? match?.team1Balls : 0 : '0'})*/}
                     {/*                                                    </font>*/}
                     {/*                                                </div>*/}
                     {/*                                            </div>*/}

                     {/*                                            <div className="col-12 row p-3">*/}
                     {/*                                                <div className="col-2 p-0"><img src={match?.team2PicURL} style={{height: '46px', maxWidth: '46px'}}></img></div>*/}
                     {/*                                              <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{match?.team2Name}</div>*/}
                     {/*                                                <div className="col-4 text-end p-0">*/}
                     {/*                                                    <font className={classNames(styles.text24SolinaItaBold)}*/}
                     {/*                                                          style={{color: match?.winnerId == 2 ? "#17EA85" : "#95A6B7"}}>{match?.team2Runs ? match?.team2Runs : '0'}/{match?.team2Wickets ? match?.team2Wickets : '0'}</font><br/>*/}
                     {/*                                                    <font className={classNames(styles.text12OS)}>({match?.team2Overs ? match?.team2balls != 6 ? match?.team2Overs - 1*/}
                     {/*                                                        : match?.team2Overs : '0'}.{match?.team2Balls ? match?.team2Balls != 6 ? match?.team2Balls : 0 : '0'})</font>*/}
                     {/*                                                </div>*/}
                     {/*                                            </div>*/}
                     {/*                                        </div>*/}
                     {/*                                    </Link>*/}
                     {/*                                </div>*/}
                     {/*                            ))}*/}
                     {/*                        </div>}*/}

                     {/*                    </> :*/}
                     {/*                    <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>*/}
                     {/*                        {t("TEAM_PROFILE.NO_UPCOMING_MATCHES_AT_THE_MOMENT")}*/}
                     {/*                    </div>*/}
                     {/*                }*/}
                     {/*               */}

                     {/*                /!*Team kits and sponsors*!/*/}
                     {/*              { getConf(TEAM_PROFILE_PAGE.SHOW_KITS_SPONSORS) ?*/}
                     {/*                <div>*/}
                     {/*                <div className="mt-2">*/}
                     {/*                    <div className={classNames(styles.SubTopic, "mt-5", "mb-4", "float-start")}>{t("TEAM_PROFILE.KITS_AND_SPONSORS")}</div>*/}
                     {/*                </div>*/}
                     {/*                <div className="col-12 p-0 row">*/}
                     {/*                    <div className="col-12 col-sm-6 col-md-4 p-0 pe-0 pe-md-3">*/}
                     {/*                        {teamKits && teamKits[0] ? <>*/}
                     {/*                            <div className={classNames(styles.OverviewBox, styles.borderLeftBlue, "p-0")}>*/}
                     {/*                                <div className={classNames(styles.borderTopBlue, "p-0")}>*/}
                     {/*                                    <div className={classNames(styles.borderRightBlue, "p-0")}>*/}
                     {/*                                        <div className={classNames(styles.borderBotBlue, styles.BgKitsPatter, "p-2", "text-center")}>*/}
                     {/*                                            <img src={teamKits[0]?.imageUrl} style={{padding: "20px", height: '216.47px', width: '204.9px'}}></img>*/}
                     {/*                                            <div className={classNames(styles.text18OSW, "text-center", "p-3")}>{teamKits[0]?.name}</div>*/}
                     {/*                                        </div>*/}
                     {/*                                    </div>*/}
                     {/*                                </div>*/}
                     {/*                            </div>*/}
                     {/*                        </> : <></>}*/}
                     {/*                    </div>*/}
                     {/*                    <div className="col-4 p-0 ps-2 pe-2">*/}
                     {/*                        {teamKits && teamKits[1] ? <>*/}
                     {/*                            <div className={classNames(styles.OverviewBox, styles.borderLeftYe, "p-0")}>*/}
                     {/*                                <div className={classNames(styles.borderTopYe, "p-0")}>*/}
                     {/*                                    <div className={classNames(styles.borderRightYe, "p-0")}>*/}
                     {/*                                        <div className={classNames(styles.borderBotYe, styles.BgKitsPatter, "p-2", "text-center")}>*/}
                     {/*                                            <img src={teamKits[1]?.imageUrl} style={{padding: "20px", height: '216.47px', width: '204.9px'}}></img>*/}
                     {/*                                            <div className={classNames(styles.text18OSW, "text-center", "p-3")}>{teamKits[1]?.name}</div>*/}
                     {/*                                        </div>*/}
                     {/*                                    </div>*/}
                     {/*                                </div>*/}
                     {/*                            </div>*/}
                     {/*                        </> : <></>}*/}
                     {/*                    </div>*/}

                     {/*                    <div className="col-4 p-0 ps-3">*/}
                     {/*                        {teamKits && teamKits[2] ? <>*/}
                     {/*                            <div className={classNames(styles.OverviewBox, styles.borderLeftDblue, "p-0")}>*/}
                     {/*                                <div className={classNames(styles.borderTopDblue, "p-0")}>*/}
                     {/*                                    <div className={classNames(styles.borderRightDblue, "p-0")}>*/}
                     {/*                                        <div className={classNames(styles.borderBotDblue, styles.BgKitsPatter, "p-2", "text-center")}>*/}
                     {/*                                            <img src={teamKits[2]?.imageUrl} style={{padding: "20px", height: '216.47px', width: '204.9px'}}></img>*/}
                     {/*                                            <div className={classNames(styles.text18OSW, "text-center", "p-3")}>{teamKits[2]?.name}</div>*/}
                     {/*                                        </div>*/}
                     {/*                                    </div>*/}
                     {/*                                </div>*/}
                     {/*                            </div>*/}
                     {/*                        </> : <></>}*/}
                     {/*                    </div>*/}
                     {/*                </div>*/}
                     {/*                {*/}
                     {/*                    !teamSponsors && !teamKits ? <div className="col-12 p-0 row mt-4">*/}
                     {/*                        <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>*/}
                     {/*                            {t("TEAM_PROFILE.NO_TEAM_KITS_OR_SPONSORS_AVAILABLE_AT_THE_MOMENT")}*/}
                     {/*                        </div>*/}
                     {/*                    </div> : <div className="col-12 p-0 row mt-4">*/}
                     {/*                        <div className="col-4 p-0 pe-3">*/}
                     {/*                            {teamSponsors && teamSponsors[0] ? <>*/}
                     {/*                                <div className={classNames(styles.OverviewBox, "p-0")}>*/}
                     {/*                                    <div className="text-center p-5 pb-3">*/}
                     {/*                                        <img src={teamSponsors[0]?.imageUrl} style={{height: "90px", width: '146px'}}></img>*/}
                     {/*                                    </div>*/}
                     {/*                                    <div className={classNames(styles.text18OSW, "text-center", "p-4")}>{teamSponsors[0]?.name}</div>*/}
                     {/*                                </div>*/}
                     {/*                            </> : <></>}*/}
                     {/*                        </div>*/}
                     {/*                        <div className="col-4 p-0 ps-2 pe-2">*/}
                     {/*                            {teamSponsors && teamSponsors[1] ? <>*/}
                     {/*                                <div className={classNames(styles.OverviewBox, "p-0")}>*/}
                     {/*                                    <div className="text-center p-5 pb-3">*/}
                     {/*                                        <img src={teamSponsors[1]?.imageUrl} style={{height: "90px", width: '146px'}}></img>*/}
                     {/*                                    </div>*/}
                     {/*                                    <div className={classNames(styles.text18OSW, "text-center", "p-4")}>{teamSponsors[1]?.name}</div>*/}
                     {/*                                </div>*/}
                     {/*                            </> : <></>}*/}
                     {/*                        </div>*/}

                     {/*                        <div className="col-4 p-0 ps-3">*/}
                     {/*                            {teamSponsors && teamSponsors[2] ? <>*/}
                     {/*                                <div className={classNames(styles.OverviewBox, "p-0")}>*/}
                     {/*                                    <div className="text-center p-5 pb-3">*/}
                     {/*                                        <img src={teamSponsors[2]?.imageUrl} style={{height: "90px", width: '146px'}}></img>*/}
                     {/*                                    </div>*/}
                     {/*                                    <div className={classNames(styles.text18OSW, "text-center", "p-4")}>{teamSponsors[2]?.name}</div>*/}
                     {/*                                </div>*/}
                     {/*                            </> : <></>}*/}
                     {/*                        </div>*/}
                     {/*                    </div>*/}
                     {/*                }</div>*/}
                     {/*                : <></> }*/}

                     {/*                /!*Latest News*!/*/}
                     {/*              { getConf(TEAM_PROFILE_PAGE.SHOW_NEWS) ?*/}
                     {/*                <>*/}
                     {/*                <div className="mt-2">*/}
                     {/*                    <div className={classNames(styles.SubTopic, "mt-5", "mb-4", "float-start")}>{t("TEAM_PROFILE.LATEST_NEWS")}</div>*/}
                     {/*                  {totalTeamNews > 6 && viewAllNews ? <>*/}
                     {/*                        <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewAll('latestNews')}>{t("TEAM_PROFILE.VIEW_ALL")}</div>*/}
                     {/*                  </> : <>*/}
                     {/*                    {totalTeamNews > 6 && (viewAllNews == false) ?*/}
                     {/*                      <div className={classNames(styles.flatBtn, "float-end", "p-2", "ps-3", "pe-3", "mt-5", "mb-4")} style={{cursor: 'pointer'}} onClick={() => ViewLess('latestNews')}>{t("TEAM_PROFILE.VIEW_LESS")}</div>*/}
                     {/*                      : <></>*/}
                     {/*                    }*/}
                     {/*                  </>}*/}
                     {/*                </div>*/}

                     {/*                {teamNews ?*/}
                     {/*                    <>*/}
                     {/*                      {loadingNews ? <Puff height={'2em'} strokeWidth={5} speed={2}/> :*/}
                     {/*                        <div className="col-12 p-0 row ">*/}
                     {/*                            {teamNews?.map((news, i) => (*/}
                     {/*                                <div className="col-12 col-sm-6 col-md-4 p-0 pe-0 pe-md-3 pb-3">*/}
                     {/*                                    <div className={classNames(styles.OverviewBox, "p-3")}>*/}
                     {/*                                        <div className={classNames(styles.text12OS, "pt-2", "pb-2")}>{formatDate(news.createdDateTime)}</div>*/}
                     {/*                                        <div className={classNames(styles.text18OSW, "pt-2")}>{news.heading}</div>*/}
                     {/*                                        <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>*/}
                     {/*                                            {news.content}*/}
                     {/*                                        </div>*/}
                     {/*                                    </div>*/}
                     {/*                                </div>*/}
                     {/*                            ))}*/}
                     {/*                        </div>}*/}

                     {/*                    </> :*/}
                     {/*                    <div className={classNames(styles.text14OS, "pt-3", "pb-2")} style={{lineHeight: "18px"}}>*/}
                     {/*                        {t("TEAM_PROFILE.NO_NEWS_AVAILABLE_AT_THE_MOMENT")}*/}
                     {/*                    </div>*/}
                     {/*                }</>*/}
                     {/*              : <></> }*/}
                     {/*            </div>*/}
                     {/*        </div>*/}
                     {/*        <div className="d-none d-lg-block col-lg-3 p-0 pt-0">*/}
                     {/*            <div className="col-11 p-0 pb-3 float-right">*/}
                     {/*                <img src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner4.webp" style={{width: "100%"}}></img>*/}
                     {/*            </div>*/}
                     {/*        </div>*/}
                     {/*</>*/}
                  </div>}
            </div>
            <Footer/>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      profileImage: state.auth.imageURL,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      refresh: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamProfile);
